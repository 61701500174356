import { useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  fetchUnpublishedProductQueryKey,
  useArchiveUnpublishedProduct,
  useStoreSettings,
  useUpdateUnpublishedProduct,
} from '@jane/business-admin/data-access';
import {
  useCatchErrorsWithManager,
  useHasPermissions,
  useMutationStatusToasts,
} from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { UnpublishedProduct } from '@jane/business-admin/types';
import {
  EventNames,
  MODAL_CARD_WIDTH,
  ModalNames,
  businessPaths,
  normalizePath,
  parseValidationErrors,
  track,
} from '@jane/business-admin/util';
import { Permission } from '@jane/shared/auth';
import {
  Button,
  Card,
  Flex,
  Form,
  FormValidationError,
  Modal,
  useForm,
} from '@jane/shared/reefer';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { DetailsCard } from '../DetailsCard';
import { ImagesCard } from './ImagesCard';

interface UnpublishedProductModalProps {
  product: UnpublishedProduct;
}

const FORM_ERROR_NAME = 'unpublished-product-edit-error';

export const UnpublishedProductModal = ({
  product,
}: UnpublishedProductModalProps) => {
  const { storeId } = useContext(StoreDetailsContext);
  const { product_id = '' } = useParams<'product_id'>();
  const queryClient = useQueryClient();
  const { data: storePayload, isFetching: storeSettingsLoading } =
    useStoreSettings(storeId);

  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (product_id) {
      setOpened(true);
    }
  }, [product_id]);

  const { pathname } = useLocation();
  const handleClose = () => {
    track({
      event: EventNames.ClosedModal,
      modal_name: ModalNames.UpdateMenuProduct,
      url: normalizePath(pathname, storeId),
    });
    navigate(businessPaths.storeProducts(storeId, 'unpublished'));
    setOpened(false);
  };

  const userCanEditProducts = useHasPermissions([
    Permission.EditUnpublishedProducts,
  ]);
  const isEditable = useMemo(() => userCanEditProducts, [userCanEditProducts]);

  const catchSubmitErrors = useCatchErrorsWithManager(
    `Error updating unpublished product. Please try again.`
  );

  const formMethods = useForm({
    defaultValues: {
      brand: product.pos_data.brand || '',
      brand_subtype: product.pos_data.brand_subtype,
      category: product.pos_data.kind,
      description: product.pos_data.description,
      dosage: product.pos_data.dosage,
      lineage:
        product.pos_data.category ||
        (product.pos_data.kind === 'flower' ? 'cbd' : 'none'),
      name: product.pos_data.name,
      pos_sku: product.pos_data.sku,
      status: product.status,
    },
  });

  const {
    formState: { isDirty, dirtyFields },
  } = formMethods;

  const {
    mutateAsync: updateUnpublishedProduct,
    isLoading: isUpdating,
    isSuccess: isUpdateSuccess,
    isError: isUpdateError,
  } = useUpdateUnpublishedProduct(storeId as string);

  const {
    mutateAsync: archiveUnpublishedProduct,
    isLoading: isArchiving,
    isSuccess: isArchiveSuccess,
    isError: isArchiveError,
  } = useArchiveUnpublishedProduct(storeId as string);

  const onSubmit = (values: any) => {
    const requestData: any = {
      product: {
        ...omit(values, ['lineage', 'category', 'status', 'pos_sku']),
        category: values['lineage'] === 'none' ? null : values['lineage'],
        kind: values['category'],
        percent_cbd: 0,
        percent_thc: 0,
        thc_dosage_milligrams: 0,
        cbd_dosage_milligrams: 0,
        unmapped_product_id: product.unmapped_product_id,
      },
    };

    const submitMethod = () => {
      track({
        action: 'published',
        event: EventNames.EditedStoreProduct,
        changed_attributes: Object.keys(dirtyFields),
        modal_name: ModalNames.UpdateUnpublishedProduct,
        type: 'edited store level unpublished product',
        url: normalizePath(pathname, storeId),
        successful: isSuccess,
      });

      queryClient.invalidateQueries({
        queryKey: fetchUnpublishedProductQueryKey(storeId, {}),
      });
      return updateUnpublishedProduct(requestData);
    };

    return catchSubmitErrors({
      submitMethod,
      requestData,
      onValidationError: (validationErrors: Record<string, unknown>) => {
        throw new FormValidationError(
          FORM_ERROR_NAME,
          parseValidationErrors(validationErrors)
        );
      },
    });
  };

  const isLoading = useMemo(() => {
    return storeSettingsLoading;
  }, [storeSettingsLoading]);

  const isSuccess = useMemo(
    () => isUpdateSuccess || isArchiveSuccess,
    [isUpdateSuccess, isArchiveSuccess]
  );

  useMutationStatusToasts({
    isMutating: isUpdating || isArchiving,
    isSuccess: isUpdateSuccess || isArchiveSuccess,
    isError: isUpdateError || isArchiveError,
    successMessage: `Product ${isUpdateSuccess ? 'published' : 'archived'}`,
    errorMessage: `Error ${
      isUpdateError ? 'updating' : 'archiving'
    } unpublished product. Please try again.`,
  });

  useEffect(() => {
    if (isSuccess) {
      handleClose();
    }
  }, [isSuccess]);

  const onArchive = () => {
    queryClient.invalidateQueries({
      queryKey: fetchUnpublishedProductQueryKey(storeId, {}),
    });
    archiveUnpublishedProduct([product.unmapped_product_id]);
  };

  return (
    <ConfirmWrapperWithTracking
      open={opened}
      setOpen={handleClose}
      hasChanges={isDirty}
      variant="full-screen"
      background="grays-ultralight"
      modalName={ModalNames['UpdateUnpublishedProduct']}
    >
      <Form.BaseForm
        name="unpublished product settings"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title={product.pos_data.name || ''}
          subtitle={storePayload?.store.name}
          actions={
            <>
              {isEditable && (
                <Button
                  variant="secondary"
                  label="Archive"
                  data-testid="unpublished-product-archive"
                  loading={isLoading}
                  mr={8}
                  onClick={async () => await onArchive()}
                />
              )}
              {isEditable && (
                <Form.SubmitButton
                  label="Publish"
                  variant="primary"
                  data-testid="unpublished-product-publish"
                  loading={isLoading}
                />
              )}
            </>
          }
        />
        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <Flex alignItems="center" flexDirection="column">
            <Card border="grays-light" width={MODAL_CARD_WIDTH} mb={32}>
              <Card.Content>
                <Flex p={24} flexDirection="column">
                  <DetailsCard
                    attributes={{
                      amount: product.pos_data.amount,
                      description: product.pos_data.description,
                      productBrandSubtype: product.pos_data.brand_subtype,
                      defaultProductName: product.pos_data.name,
                      dosage: product.pos_data.dosage,
                      fromPOS: true,
                      brand: product.pos_data.brand,
                      lineage: product.pos_data.category,
                    }}
                    menuProduct={product}
                    isEditable={isEditable}
                    customLabels={storePayload?.custom_labels}
                    isSelfPublished={false}
                    isUnpublished={true}
                    tableType="unpublished"
                  />
                </Flex>
              </Card.Content>
            </Card>
            <Flex p={24} flexDirection="column">
              <ImagesCard isLoading={isLoading} unpublishedProduct={product} />
            </Flex>
          </Flex>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};
