import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { MainMenuUrlInput } from '@jane/business-admin/components';
import {
  useFetchCurrentBloomMenu,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import type { BloomMainMenuForm } from '@jane/business-admin/types';
import { ConfirmChangeModal, SortableList } from '@jane/shared/components';
import {
  AddIcon,
  Button,
  ChevronDownIcon,
  Flex,
  Form,
  TrashIcon,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { ModalPageWrapper } from './components/ModalPageWrapper';
import { PlusSubMenuItems } from './components/PlusSubMenuItems';

interface ContentContainerProps {
  hasMenuItems: boolean;
}

const ContentContainer = styled.div<ContentContainerProps>`
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  gap: ${({ hasMenuItems }) =>
    hasMenuItems
      ? '4px'
      : '24px'}; // 4px instead of 16px to account for an empty space above the form field
  flex: 1 0 0;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.05),
    0px 0px 0px 1px rgba(0, 0, 0, 0.05);
`;

const EmptyStateContainer = styled.div`
  display: flex;
  padding: 28px 0px;
  justify-content: center;
  align-self: stretch;
  border-radius: 16px;
  border: 1px dashed #dadada;
  background: #f7f7f7;
`;

const LabelFieldContainer = styled.div`
  width: 152px;
`;

const LabelField = styled(Form.TextField)`
  width: 152px;
  div {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const UrlFieldContainer = styled.div`
  width: 100%;
`;

const AddSubItemButton = styled(Button)`
  div {
    font-weight: normal;
  }
`;

const AddMenuItemButtonContainer = styled.div`
  width: 170px;
`;

const AddMenuItemContainer = styled.div`
  cursor: pointer;
`;

const StyledSortableList = styled(SortableList)`
  gap: 4px !important;

  [aria-label='drag handle'] {
    margin-top: 24px;
  }

  .sortable-element {
    align-items: flex-start;
  }
`;

export const PlusMainMenuSettings = () => {
  const [addSubItemClicked, setAddSubItemClicked] = useState<number | null>(
    null
  );
  const [discardFunction, setDiscardFunction] = useState<(() => void) | null>(
    null
  );

  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const { mutate: updateBloomMenu, isLoading } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );

  const {
    control,
    reset,
    formState: { isValid, isDirty },
    handleSubmit,
    watch,
  } = useForm<BloomMainMenuForm>();

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'menuItems',
  });

  const toast = useToast();

  const publish = (data: BloomMainMenuForm) => {
    updateBloomMenu(
      {
        theme: {
          ...bloomMenuQuery.data?.theme?.bloom,
          main_menu: data.menuItems,
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          toast.add({
            label: 'Your settings have been saved!',
            variant: 'success',
          });
          reset({ menuItems: data.menuItems });
        },
      }
    );
  };
  const onPreview = useCallback(() => {
    updateBloomMenu(
      {
        theme_draft: {
          ...bloomMenuQuery.data?.theme?.bloom,
          main_menu: menuItems,
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          if (!bloomMenuQuery.data?.menu_url) {
            return;
          }
          const previewUrl = new URL(bloomMenuQuery.data.menu_url);
          previewUrl.searchParams.set('draft', 'true');
          const newWindow = window.open(
            previewUrl,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) {
            newWindow.opener = null;
          }
        },
      }
    );
  }, [fields, bloomMenuQuery, updateBloomMenu]);

  const onMenuAdd = () => {
    append({ label: '', destination: '', links: [] });
  };
  const disablePreview = fields.length === 0 || !isValid || isLoading;
  const disablePublish = disablePreview || !isDirty;

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      reset({
        menuItems: bloomMenuQuery.data.theme.bloom?.main_menu || [],
      });
    }
  }, [
    bloomMenuQuery.data?.theme.bloom?.main_menu,
    bloomMenuQuery.isSuccess,
    reset,
  ]);

  const menuItems = watch(`menuItems`);

  /**
   * Mirrors the `deleteItem` function inside the PlusSubMenuItems component.
   * This sets a discard function in state so that the appropriate `remove`
   * function can be called when the user confirms the deletion.
   */
  const deleteItem = useCallback(
    (index: number) => {
      const item = menuItems[index];
      if (!item) {
        return;
      }
      const discardFunc = () => {
        remove(index);
      };
      if (
        item.destination ||
        item.label ||
        (item.links?.length && item.links.length > 0)
      ) {
        setDiscardFunction(() => discardFunc);
      } else {
        discardFunc();
      }
    },
    [setDiscardFunction, menuItems, remove]
  );

  return (
    <ModalPageWrapper
      hasUnsavedChanges={isDirty}
      preview={{ onClick: onPreview, disable: disablePreview }}
      publish={{
        onClick: handleSubmit(publish),
        loading: isLoading,
        disable: disablePublish,
      }}
      title="Main menu"
    >
      <Flex px={40} pt={24} pb={48} gap={24} flexDirection="column">
        <Typography variant="body">
          Set up the items that will appear in the main menu. Drag each menu
          option into the order you prefer.
        </Typography>
        <ContentContainer hasMenuItems={fields.length > 0}>
          <AddMenuItemButtonContainer>
            <Button
              label="Add menu item"
              type="button"
              variant="secondary"
              onClick={onMenuAdd}
              startIcon={<AddIcon size="sm" />}
            />
          </AddMenuItemButtonContainer>
          {fields.length === 0 ? (
            <EmptyStateContainer>
              <Typography variant="body" color="grays-mid">
                Your menu is empty
              </Typography>
            </EmptyStateContainer>
          ) : (
            <Flex flexDirection="column" gap={8}>
              <Form name="url-form" onSubmit={noop}>
                <Flex gap={4} flexDirection="column">
                  <StyledSortableList
                    direction="vertical"
                    items={fields}
                    onChange={(_, oldIndex, newIndex) => {
                      move(oldIndex, newIndex);
                    }}
                    variableHeights
                    renderItem={(item, idx) => {
                      return (
                        <div key={item.id}>
                          <Flex alignItems="center">
                            <Controller
                              name={`menuItems.${idx}.label`}
                              control={control}
                              rules={{ required: true, pattern: /\S+/ }}
                              render={({ field }) => (
                                <LabelFieldContainer>
                                  <LabelField
                                    startIcon={
                                      menuItems[idx].links?.length ? (
                                        <ChevronDownIcon
                                          color="purple"
                                          size="sm"
                                          mr={12}
                                        />
                                      ) : null
                                    }
                                    label=""
                                    defaultValue={field.value}
                                    placeholder="Add label"
                                    {...field}
                                  />
                                </LabelFieldContainer>
                              )}
                            />
                            <Controller
                              name={`menuItems.${idx}.destination`}
                              control={control}
                              render={({ field }) => (
                                <UrlFieldContainer>
                                  <MainMenuUrlInput field={field} />
                                </UrlFieldContainer>
                              )}
                            />
                            <AddSubItemButton
                              variant="primary-inverse"
                              label="Add sub-menu item"
                              onClick={() => setAddSubItemClicked(idx)}
                              mt={16}
                              ml={8}
                              mr={8}
                            />
                            <TrashIcon
                              mt={16}
                              size="lg"
                              color="grays-mid"
                              onClick={() => deleteItem(idx)}
                              altText={`Remove item ${idx + 1}`}
                            />
                          </Flex>
                          <PlusSubMenuItems
                            parentIndex={idx}
                            control={control}
                            addSubItemClicked={addSubItemClicked}
                            setAddSubItemClicked={setAddSubItemClicked}
                            setDiscardFunction={setDiscardFunction}
                            watch={watch}
                          />
                        </div>
                      );
                    }}
                  />
                </Flex>
              </Form>
              <Flex mt={16} ml={48}>
                <AddMenuItemContainer onClick={onMenuAdd}>
                  <Typography variant="body" color="purple">
                    Add menu item
                  </Typography>
                </AddMenuItemContainer>
              </Flex>
            </Flex>
          )}
        </ContentContainer>
      </Flex>

      <ConfirmChangeModal
        open={!!discardFunction}
        title={
          <Typography variant="title-bold" mb={8}>
            Are you sure?
          </Typography>
        }
        onConfirm={() => {
          discardFunction?.();
          setDiscardFunction(null);
        }}
        onCancel={() => setDiscardFunction(null)}
        confirmButtonVariant="destructive"
        confirmLabel="Yes, remove"
        cancelLabel="No, go back"
        subtitle="Removing this row cannot be undone."
        minWidth="432px"
        borderRadius="40px"
      />
    </ModalPageWrapper>
  );
};
