import * as t from 'io-ts';

import { tCloudflareImage } from './cloudflareImage';

export const tKioskV2 = t.interface({
  allow_anonymous_checkout: t.union([t.boolean, t.null]),
  checkout_confirmation_message: t.union([t.string, t.null, t.undefined]),
  disable_phone_number_at_checkout: t.union([t.boolean, t.null]),
  enabled: t.boolean,
  frost_landing_page: t.union([t.boolean, t.null]),
  id: t.number,
  inactivity_timeout_ms: t.union([t.number, t.null]),
  kiosk_photo: t.union([tCloudflareImage, t.null]),
  refresh_to_landing_timeout_ms: t.union([t.number, t.null]),
  show_checkout_message_field: t.union([t.boolean, t.null]),
  skip_landing_page: t.union([t.boolean, t.null]),
});

export type KioskV2 = t.TypeOf<typeof tKioskV2>;
