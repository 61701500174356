import styled from '@emotion/styled';
import noop from 'lodash/noop';
import { useCallback, useEffect } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';

import {
  useFetchCurrentBloomMenu,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import { Box, Flex, Form, Typography, useToast } from '@jane/shared/reefer';

import { ModalPageWrapper } from './components/ModalPageWrapper';

const GoogleSSOContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '24px',
  margin: '40px',
  padding: '40px',
  boxShadow:
    '0px 2px 16px 0px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
  gap: '24px',
  maxWidth: '864px',
});

const StyledLink = styled('a')({
  textDecoration: 'underline',
});

export const PlusGoogleSSO = () => {
  const toast = useToast();
  const bloomMenuQuery = useFetchCurrentBloomMenu();

  const {
    control,
    handleSubmit,
    formState: { isDirty, isValid },
    reset,
  } = useForm({
    defaultValues: {
      client_id: bloomMenuQuery.data?.google_sso_client_id ?? '',
      client_secret: bloomMenuQuery.data?.google_sso_client_secret ?? '',
    },
  });

  const { mutate: updateBloomMenu, isLoading } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );

  const publish: SubmitHandler<FieldValues> = useCallback(
    (data) => {
      updateBloomMenu(
        {
          google_sso_client_id: data['client_id'],
          google_sso_client_secret: data['client_secret'],
        },
        {
          onError: (e) => {
            toast.add({
              label: 'An error occurred.',
              variant: 'error',
            });
          },
          onSuccess: () => {
            toast.add({
              label: 'Menu updated!',
              variant: 'success',
            });
            reset({
              client_id: data['client_id'],
              client_secret: data['client_secret'],
            });
          },
        }
      );
    },
    [updateBloomMenu, toast, reset]
  );

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      reset({
        client_id: bloomMenuQuery.data?.google_sso_client_id ?? '',
        client_secret: bloomMenuQuery.data?.google_sso_client_secret ?? '',
      });
    }
  }, [
    bloomMenuQuery.data?.google_sso_client_id,
    bloomMenuQuery.data?.google_sso_client_secret,
    bloomMenuQuery.isSuccess,
    reset,
  ]);

  return (
    <ModalPageWrapper
      hasUnsavedChanges={isDirty}
      publish={{
        onClick: handleSubmit(publish),
        disable: isLoading || !isDirty || !isValid,
        loading: isLoading,
      }}
      title="Google SSO"
    >
      <Form name="google_sso_form" onSubmit={noop}>
        <Flex width="100%" justifyContent="center">
          <GoogleSSOContainer>
            <Typography>
              You can set up single sign-on (SSO) with Google so that your
              customers can sign in with Google. Allowing your customers to sign
              in with Google will reduce friction in your customers' login
              experience.
            </Typography>
            <Typography>
              Follow the steps in this{' '}
              <StyledLink href="https://docs.iheartjane.com/partner-success/men-you/jane-bloom">
                guide
              </StyledLink>{' '}
              to set up Google SSO for your menu.
            </Typography>
            <Flex gap={24}>
              <Controller
                name="client_id"
                control={control}
                render={({ field }) => (
                  <Form.TextField {...field} label="Google SSO Client ID" />
                )}
              />
              <Controller
                name="client_secret"
                control={control}
                render={({ field }) => (
                  <Form.TextField {...field} label="Google SSO Client Secret" />
                )}
              />
            </Flex>
          </GoogleSSOContainer>
        </Flex>
      </Form>
    </ModalPageWrapper>
  );
};
