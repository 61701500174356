import omit from 'lodash/omit';
import type { ReactNode } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import type { KioskRequest } from '@jane/business-admin/data-access';
import { useUpdateKiosk } from '@jane/business-admin/data-access';
import { useCatchErrorsWithManager } from '@jane/business-admin/hooks';
import { StoreDetailsContext } from '@jane/business-admin/providers';
import type { KioskV2 } from '@jane/business-admin/types';
import {
  MAX_IMAGE_SIZE,
  ModalNames,
  VALID_IMAGE_TYPES,
  changeKioskTimeout,
  validateImage,
} from '@jane/business-admin/util';
import {
  Button,
  EditIcon,
  Flex,
  Form,
  Image,
  Modal,
  Skeleton,
  Typography,
  useForm,
  useFormContext,
} from '@jane/shared/reefer';
import { ImagePreview, duration } from '@jane/shared/util';

import { ConfirmWrapperWithTracking } from '../../../../../ConfirmWrapperWithTracking';
import { InfoPopoverWrapper } from '../../../../../InfoPopoverWrapper';

interface FormData {
  allow_anonymous_checkout?: boolean;

  // Just for display purposes, gets stripped out when API request is made
  display?: any;
  frost_landing_page?: boolean;
  id?: number;
  inactivity_timeout_ms?: number;
  kiosk_photo?: string;
  password?: string;
  refresh_to_landing_timeout_ms?: number;
  show_checkout_message_field?: boolean;
  skip_landing_page?: boolean;
}

const startingScreenOptions = [
  {
    label: 'Menu',
    value: 'menu',
    id: 'menu',
  },
  {
    label: (
      <Flex>
        Landing Page
        <InfoPopoverWrapper
          label="Landing page"
          popoverContent={
            <Typography>
              Display your store logo and an "Order Now" button before accessing
              the menu.
            </Typography>
          }
        />
      </Flex>
    ),
    value: 'landing_page',
    id: 'landing_page',
  },
  {
    label: (
      <Flex>
        Frosted Landing Page
        <InfoPopoverWrapper
          label="Frosted landing page"
          popoverContent={
            <Typography>
              Overlay your store logo and an "Order Now" button on top of a
              blurred menu
            </Typography>
          }
        />
      </Flex>
    ),
    value: 'frosted_landing_page',
    id: 'frosted_landing_page',
  },
];

// TODO: Make this a reusable wrapper component
const ImagePickerWrapper = ({
  onImageSelect,
  onFailure,
  onSuccess,
  children,
}: {
  children: ({ open }: { open: () => void }) => ReactNode;
  onFailure: () => void;
  onImageSelect: () => void;
  onSuccess: (image: string) => void;
}) => {
  const onDrop = useCallback(
    async (files: File[]) => {
      onImageSelect();
      const file = files[0];

      if (!file || !validateImage(file)) {
        return onFailure && onFailure();
      }

      const image = await ImagePreview.parse(file);
      onSuccess(image.url);
    },
    [onSuccess]
  );
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: VALID_IMAGE_TYPES.join(','),
    onDrop,
    multiple: false,
    maxSize: MAX_IMAGE_SIZE,
    noClick: true,
  });

  return (
    <div {...getRootProps()}>
      {children({ open })}
      <input {...getInputProps()} />
    </div>
  );
};

const AppearanceSection = ({ kioskSettings }: { kioskSettings: KioskV2 }) => {
  const { setValue, watch } = useFormContext();
  const formStartingScreen = watch('display.starting_screen');

  const [isImageLoading, setIsImageLoading] = useState(false);
  const [kioskPhoto, setKioskPhoto] = useState(
    kioskSettings?.kiosk_photo?.file.url
  );

  useEffect(() => {
    if (kioskSettings.skip_landing_page && !kioskSettings.frost_landing_page) {
      setValue('display.starting_screen', startingScreenOptions[0].value);
    } else if (
      !kioskSettings.skip_landing_page &&
      !kioskSettings.frost_landing_page
    ) {
      setValue('display.starting_screen', startingScreenOptions[1].value);
    } else {
      setValue('display.starting_screen', startingScreenOptions[2].value);
    }
  }, [kioskSettings.skip_landing_page, kioskSettings.frost_landing_page]);

  useEffect(() => {
    if (formStartingScreen === 'menu') {
      setValue('skip_landing_page', true);
      setValue('frost_landing_page', false);
    } else if (formStartingScreen === 'landing_page') {
      setValue('skip_landing_page', false);
      setValue('frost_landing_page', false);
    } else if (formStartingScreen === 'frosted_landing_page') {
      setValue('skip_landing_page', false);
      setValue('frost_landing_page', true);
    }
  }, [formStartingScreen]);

  const updateImage = (image: string) => {
    setValue('kiosk_photo', image, { shouldDirty: true });
    setKioskPhoto(image);
    setIsImageLoading(false);
  };

  const imageUploadFailure = () => {
    console.error('error with image');
    setIsImageLoading(false);
  };

  return (
    <>
      <Typography mb={24} variant="body-bold">
        Appearance
      </Typography>
      <Form.RadioFieldGroup
        name="display.starting_screen"
        options={startingScreenOptions}
        legend={<Typography color="grays-mid">Starting screen</Typography>}
      />
      <Flex mb={24} justifyContent="space-between" alignItems="center">
        <Flex>
          <InfoPopoverWrapper
            label="Store logo"
            popoverContent={
              <Typography>
                Source file should be at least 300x300 pixels for best results.
              </Typography>
            }
          >
            <Typography color="grays-mid">Store logo</Typography>
          </InfoPopoverWrapper>
        </Flex>
        <ImagePickerWrapper
          onImageSelect={() => setIsImageLoading(true)}
          onSuccess={updateImage}
          onFailure={imageUploadFailure}
        >
          {({ open }) => (
            <Button.Icon
              variant="secondary"
              onClick={open}
              icon={<EditIcon />}
            />
          )}
        </ImagePickerWrapper>
      </Flex>
      {isImageLoading ? (
        <Skeleton animate>
          <Skeleton.Bone width={104} height={104} />
        </Skeleton>
      ) : (
        <Image
          altText={'Store logo'}
          height="104px"
          width="104px"
          border={true}
          src={kioskPhoto || ''}
          borderRadius="rounded"
        />
      )}
    </>
  );
};

const TimeoutSection = ({
  inactivityTimeout,
  refreshTimeout,
}: {
  inactivityTimeout: number;
  refreshTimeout: number;
}) => {
  const { setValue } = useFormContext();

  const [combinedTimeouts, setCombinedTimeouts] = useState(
    duration(inactivityTimeout + refreshTimeout, 'milliseconds')
  );

  useEffect(() => {
    setValue('display.timeout_minutes', combinedTimeouts.minutes());
    setValue('display.timeout_seconds', combinedTimeouts.seconds());
  }, [combinedTimeouts]);

  const handleChange = (unit: 'minutes' | 'seconds') => (value: number) => {
    if (unit === 'seconds' && value > 59) return;
    const newTimeout = changeKioskTimeout(combinedTimeouts, unit, value || 0);

    /* NOTE(elliot): This is the sequence of events in the kiosk app:
          1. After inactivity_timeout_ms, a popup will display
          2. refresh_to_landing_timeout_ms after 1. the cart clears and returns to landing page

      We _should_ set these fields separately like in active admin, but this is confusing for partners,
      so we decided to display 1 value (combined) for "Clear shopping cart and return to starting screen after" and
      we divide the value in half and set both fields.
    */
    setValue('inactivity_timeout_ms', newTimeout.asMilliseconds() / 2);
    setValue('refresh_to_landing_timeout_ms', newTimeout.asMilliseconds() / 2);
    setCombinedTimeouts(newTimeout);
  };

  return (
    <>
      <Typography mb={24} variant="body-bold">
        Timeout
      </Typography>

      <Typography mb={24} color="grays-mid">
        Clear shopping cart and return to starting screen after
      </Typography>
      {/* TODO: Changing these NumberFields using up/down arrows on a keyboard doesn't work */}
      <Flex gap={24}>
        <Form.NumberField
          label="Timeout minutes"
          step={1}
          labelHidden
          endUnit="minutes"
          name="display.timeout_minutes"
          onChange={handleChange('minutes')}
        />
        <Form.NumberField
          max={59}
          step={1}
          label="Timeout seconds"
          labelHidden
          endUnit="seconds"
          name="display.timeout_seconds"
          onChange={handleChange('seconds')}
        />
      </Flex>
    </>
  );
};

const FORM_ERROR_NAME = 'kiosk-errors';

export const KioskSettingsModal = ({
  setOpen,
  kioskSettings,
  storeName,
}: {
  kioskSettings: KioskV2;
  setOpen: (open: boolean) => void;
  storeName: string;
}) => {
  const catchSubmitErrors = useCatchErrorsWithManager(
    'Error updating kiosk settings. Please try again.',
    [['kiosk_settings', 'password']]
  );

  const { storeId } = useContext(StoreDetailsContext);
  const { mutateAsync: updateKiosk, isSuccess: updateSuccess } =
    useUpdateKiosk(storeId);

  const formMethods = useForm();
  const {
    formState: { isDirty },
    setValue,
  } = formMethods;

  useEffect(() => {
    setValue('id', kioskSettings.id);
  }, [kioskSettings.id]);

  useEffect(() => {
    if (updateSuccess) {
      setOpen(false);
    }
  }, [updateSuccess]);

  const onSubmit = (data: FormData) => {
    let saveData: KioskRequest = {
      kiosk_settings: omit(data, ['display', 'kiosk_photo']),
    };
    if (data.kiosk_photo) {
      saveData = {
        ...saveData,
        kiosk_photo: data.kiosk_photo,
      };
    }
    return catchSubmitErrors({
      submitMethod: () => updateKiosk(saveData),
      requestData: saveData,
      onValidationError: () => null,
    });
  };

  return (
    <ConfirmWrapperWithTracking
      open
      setOpen={setOpen}
      hasChanges={isDirty}
      modalName={ModalNames.KioskSettings}
    >
      <Form.BaseForm
        name="Edit kiosk settings form"
        formMethods={formMethods}
        onSubmit={onSubmit}
        formErrorName={FORM_ERROR_NAME}
      >
        <Modal.Header
          title="Kiosk settings"
          subtitle={storeName}
          actions={<Form.SubmitButton variant="primary" label="Save" />}
        />

        <Modal.Content>
          <Form.ErrorBanner name={FORM_ERROR_NAME} />
          <AppearanceSection kioskSettings={kioskSettings} />

          <Modal.ContentDivider />

          <TimeoutSection
            inactivityTimeout={kioskSettings.inactivity_timeout_ms || 0}
            refreshTimeout={kioskSettings.refresh_to_landing_timeout_ms || 0}
          />

          <Modal.ContentDivider />

          <Typography mb={40} variant="body-bold">
            Security
          </Typography>
          <Flex width={373}>
            <Form.TextField
              width="100%"
              label={
                <Flex>
                  <InfoPopoverWrapper
                    label="Customer note"
                    popoverContent={
                      <Typography>
                        Unlock the kiosk menu with this password.
                      </Typography>
                    }
                  >
                    <Typography>Password</Typography>
                  </InfoPopoverWrapper>
                </Flex>
              }
              name="password"
              type="password"
            />
          </Flex>

          <Modal.ContentDivider />

          <Typography mb={40} variant="body-bold">
            Checkout
          </Typography>
          <Form.SwitchField
            defaultChecked={kioskSettings.show_checkout_message_field || false}
            mb={24}
            label="Allow customers to leave an optional note at checkout"
            name="show_checkout_message_field"
          />
          <Flex mb={40}>
            <InfoPopoverWrapper
              label="Customer note"
              popoverContent={
                <Typography>
                  If customers omit their phone number, they will not receive
                  text messages about their order status.
                </Typography>
              }
            >
              <Form.SwitchField
                defaultChecked={kioskSettings.allow_anonymous_checkout || false}
                label="Allow customers to omit their phone numbers at checkout"
                name="allow_anonymous_checkout"
              />
            </InfoPopoverWrapper>
          </Flex>

          <Form.TextField
            autocomplete="off"
            label="Purchase confirmation message"
            mb={24}
            name="checkout_confirmation_message"
            placeholder="All set! Grab your order at the counter."
            typography="body-bold"
            width="100%"
            defaultValue={kioskSettings.checkout_confirmation_message || ''}
          />
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmWrapperWithTracking>
  );
};
