import type {
  SpecialTypeMap,
  SpecialTypeMapItem,
} from '@jane/business-admin/types';
import { ADDITIONAL_WEIGHTS } from '@jane/business-admin/util';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { Flex, useFormContext } from '@jane/shared/reefer';
import { QUALIFIED_GROUP_TYPE_MAP, WEIGHT_OPTIONS } from '@jane/shared/util';

import { BulkPricing } from './special_types/BulkPricing';
import { Bundle } from './special_types/Bundle';
import { CartTotal } from './special_types/CartTotal';
import { QualifiedGroup } from './special_types/QualifiedGroup';

// Dev Note: Product has no subset of critieria so its left out of this list
const getSpecialTypeData: any = ({
  specialTypeKey,
  hasAccess,
  subKey,
}: {
  hasAccess?: boolean;
  specialTypeKey: string;
  subKey: keyof SpecialTypeMapItem;
}) => {
  const specialTypeMap: SpecialTypeMap = {
    bulk_pricing: {
      name: 'conditions.bulk_pricing',
      options: Object.values(
        hasAccess ? ADDITIONAL_WEIGHTS : WEIGHT_OPTIONS
      ).flatMap((value) => {
        if (value.id === 'each') {
          return [];
        }
        return { ...value, value: value.id };
      }),
    },
    cart_total: {
      name: 'conditions.cart_total',
    },
    bundle: {
      name: 'conditions.bundle',
    },
    qualified_group: {
      name: 'conditions.qualified_group',
      options: Object.entries(QUALIFIED_GROUP_TYPE_MAP).map(([key, value]) => {
        return { label: value, value: key };
      }),
    },
  };

  return specialTypeMap[specialTypeKey as keyof typeof specialTypeMap]?.[
    subKey
  ];
};

export const SpecialTypeCriteria = ({
  isCreateMode = false,
}: {
  isCreateMode: boolean;
}) => {
  const { watch, getValues } = useFormContext();
  const specialTypeWatch = watch('special_type');
  const specialTypeValue = getValues('special_type');

  const hasBulkSpecialsExtraWeightsAcces = useFlag(
    FLAGS.scBulkSpecialsExtraWeightsFrontend
  );

  const specialType = specialTypeWatch || specialTypeValue;

  return (
    <Flex width={'100%'}>
      {specialType === 'bulk_pricing' ? (
        <BulkPricing
          name={getSpecialTypeData({
            specialTypeKey: specialType,
            subKey: 'name',
          })}
          options={getSpecialTypeData({
            specialTypeKey: specialType,
            subKey: 'options',
            hasAccess: hasBulkSpecialsExtraWeightsAcces,
          })}
          isCreateMode={isCreateMode}
        />
      ) : null}
      {specialType === 'cart_total' ? (
        <CartTotal
          name={getSpecialTypeData({
            specialTypeKey: specialType,
            subKey: 'name',
          })}
          isCreateMode={isCreateMode}
        />
      ) : null}
      {specialType === 'qualified_group' ? (
        <QualifiedGroup
          name={getSpecialTypeData({
            specialTypeKey: specialType,
            subKey: 'name',
          })}
          options={getSpecialTypeData({
            specialTypeKey: specialType,
            subKey: 'options',
          })}
          isCreateMode={isCreateMode}
        />
      ) : null}
      {specialType === 'bundle' ? (
        <Bundle
          name={getSpecialTypeData({
            specialTypeKey: specialType,
            subKey: 'name',
          })}
          isCreateMode={isCreateMode}
        />
      ) : null}
    </Flex>
  );
};
