import styled from '@emotion/styled';
import { useMaskito } from '@maskito/react';
import isEmpty from 'lodash/isEmpty';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { hex } from 'wcag-contrast';

import { ColorPicker } from '@jane/business-admin/components';
import {
  useFetchCurrentBloomMenu,
  useFetchIframeHeader,
  useUpdateBloomMenu,
} from '@jane/business-admin/data-access';
import type { BloomTheme } from '@jane/business-admin/types';
import { normalizeUrl, urlMask } from '@jane/business-admin/util';
import { contrastingColor } from '@jane/shared-ecomm/util';
import {
  AlertIcon,
  Banner,
  Card,
  Flex,
  Form,
  FormValidationError,
  Loading,
  Tabs,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { ModalPageWrapper } from './components/ModalPageWrapper';

const WCAG_RATIO = 4.5;
const isColorAccessible = (foreground: string, background: string) =>
  hex(foreground, background) >= WCAG_RATIO;
const isHexColor = (color: string) => {
  const hexColorRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;
  return hexColorRegex.test(color);
};

interface FormData {
  [key: string]: unknown;
}

const BorderedFlex = styled(Flex)<{ hide?: boolean }>(({ theme, hide }) => ({
  display: `${hide ? 'none' : 'flex'}`,
  borderBottom: `1px solid ${theme.colors.grays.light}`,
}));

const StyledTextField = styled(Form.TextField)<{ error: boolean }>(
  ({ theme, error }) => {
    return {
      ...(error
        ? {
            div: {
              borderColor: theme.colors.system.negative.main,
              '&:focus-within': {
                borderColor: theme.colors.system.negative.main,
              },
            },
          }
        : {}),
    };
  }
);

const StyledTabs = styled(Tabs)(({ theme }) => ({
  margin: '40px auto 0',
  backgroundColor: `${theme.colors.grays.ultralight}`,
  borderRadius: `${theme.borderRadius.sm}`,
}));

const StyledTab = styled(Tabs.Tab)<{ hasError: boolean; selected: boolean }>(
  ({ theme, selected, hasError }) => ({
    boxShadow: 'none',
    border: selected ? `2px solid ${theme.colors.primary.main}` : 'none',
    borderRadius: `${theme.borderRadius.sm}`,
    position: 'relative',

    span: {
      fontWeight: 600,
    },

    '&:after': {
      display: `${hasError ? 'inline' : 'none'}`,
      content: "''",
      height: '12px',
      width: '12px',
      position: 'absolute',
      top: '15px',
      right: '10px',
      backgroundColor: '#f33',
      borderRadius: '12px',
      border: '2px solid #FFD6D6',
    },
  })
);

const StyledBanner = styled(Banner)({
  marginTop: '24px',
  maxWidth: '780px',
  width: '780px',
});

const defaultValues = {
  primaryHex: '#000000',
  secondaryHex: '#FFFFFF',
  dealsHex: '#CE349A',
  headerHex: '#FFFFFF',
  indicaHex: '#000000',
  hybridHex: '#000000',
  sativaHex: '#000000',
  cbdHex: '#000000',
};

export const PlusColors = () => {
  const urlRef = useMaskito({ options: urlMask });
  const {
    control,
    formState: { dirtyFields, errors },
    reset,
    watch,
  } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const primaryHex = watch('primaryHex');
  const secondaryHex = watch('secondaryHex');
  const dealsHex = watch('dealsHex');
  const headerHex = watch('headerHex');
  const indicaHex = watch('indicaHex');
  const hybridHex = watch('hybridHex');
  const sativaHex = watch('sativaHex');
  const cbdHex = watch('cbdHex');

  const [selectedTab, setSelectedTab] = useState<
    'color-scheme' | 'lineage-tags'
  >('color-scheme');
  const toast = useToast();
  // colors for colors-scheme tab
  const primaryContrast = contrastingColor(primaryHex, ['#0E0E0E', '#FFFFFF']);
  const inverseText = primaryContrast === '#0E0E0E' ? '#FFFFFF' : '#000000';
  const secondaryTextContrast = contrastingColor(secondaryHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);
  const dealsTextContrast = contrastingColor(dealsHex, ['#0E0E0E', '#FFFFFF']);
  const headerTextContrast = contrastingColor(headerHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);

  // colors for lineage-tags tab
  const hybridTextContrast = contrastingColor(hybridHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);
  const indicaTextContrast = contrastingColor(indicaHex, [
    '#0E0E0E',
    '#FFFFFF',
  ]);
  const sativaHexContrast = contrastingColor(sativaHex, ['#0E0E0E', '#FFFFFF']);
  const cbdHexContrast = contrastingColor(cbdHex, ['#0E0E0E', '#FFFFFF']);

  const [retailerUrl, setRetailerUrl] = useState('https://');
  const [isiFrameLoading, setIsiFrameLoading] = useState(false);
  const bloomMenuQuery = useFetchCurrentBloomMenu();
  const { mutate: updateBloomMenu, isLoading } = useUpdateBloomMenu(
    bloomMenuQuery.data?.id
  );
  const { mutateAsync: fetchIframeHeader } = useFetchIframeHeader();
  const [isIframeError, setIsIframeError] = useState(false);

  const colorUpdates = useMemo(() => {
    return {
      primary: {
        main: primaryHex,
        contrast: primaryContrast,
      },
      secondary: {
        main: secondaryHex,
        contrast: secondaryTextContrast,
      },
      header: {
        main: headerHex,
        contrast: headerTextContrast,
      },
      deals: {
        main: dealsHex,
        contrast: dealsTextContrast,
      },
      text: {
        primary_inverse: inverseText,
      },
      lineage: {
        cbd: {
          main: cbdHex,
          contrast: cbdHexContrast,
        },
        hybrid: {
          main: hybridHex,
          contrast: hybridTextContrast,
        },
        indica: {
          main: indicaHex,
          contrast: indicaTextContrast,
        },
        sativa: {
          main: sativaHex,
          contrast: sativaHexContrast,
        },
      },
    };
  }, [
    cbdHex,
    cbdHexContrast,
    dealsHex,
    dealsTextContrast,
    headerHex,
    headerTextContrast,
    hybridHex,
    hybridTextContrast,
    indicaHex,
    indicaTextContrast,
    inverseText,
    primaryContrast,
    primaryHex,
    sativaHex,
    sativaHexContrast,
    secondaryHex,
    secondaryTextContrast,
  ]);

  const publish = useCallback(() => {
    updateBloomMenu(
      {
        theme: {
          ...bloomMenuQuery.data?.theme?.bloom,
          colors: colorUpdates,
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          toast.add({
            label: 'Menu updated!',
            variant: 'success',
          });
          reset({
            primaryHex,
            secondaryHex,
            dealsHex,
            headerHex,
            indicaHex,
            hybridHex,
            sativaHex,
            cbdHex,
          });
        },
      }
    );
  }, [
    primaryHex,
    secondaryHex,
    dealsHex,
    headerHex,
    cbdHex,
    hybridHex,
    indicaHex,
    sativaHex,
    bloomMenuQuery,
    updateBloomMenu,
    colorUpdates,
    reset,
  ]);

  const preview = useCallback(() => {
    updateBloomMenu(
      {
        theme_draft: {
          ...bloomMenuQuery.data?.theme?.bloom,
          colors: {
            primary: {
              main: primaryHex,
              contrast: primaryContrast,
            },
            secondary: {
              main: secondaryHex,
              contrast: secondaryTextContrast,
            },
            header: {
              main: headerHex,
              contrast: headerTextContrast,
            },
            deals: {
              main: dealsHex,
              contrast: dealsTextContrast,
            },
            text: {
              primary_inverse: inverseText,
            },
            lineage: {
              cbd: {
                main: cbdHex,
                contrast: cbdHexContrast,
              },
              hybrid: {
                main: hybridHex,
                contrast: hybridTextContrast,
              },
              indica: {
                main: indicaHex,
                contrast: indicaTextContrast,
              },
              sativa: {
                main: sativaHex,
                contrast: sativaHexContrast,
              },
            },
          },
        },
      },
      {
        onError: () => {
          toast.add({
            label: 'An error occurred.',
            variant: 'error',
          });
        },
        onSuccess: () => {
          if (!bloomMenuQuery.data?.menu_url) {
            return;
          }
          const previewUrl = new URL(bloomMenuQuery.data.menu_url);
          previewUrl.searchParams.set('draft', 'true');
          const newWindow = window.open(
            previewUrl,
            '_blank',
            'noopener,noreferrer'
          );
          if (newWindow) {
            newWindow.opener = null;
          }
        },
      }
    );
  }, [
    primaryHex,
    secondaryHex,
    dealsHex,
    headerHex,
    cbdHex,
    hybridHex,
    indicaHex,
    sativaHex,
    bloomMenuQuery,
    updateBloomMenu,
  ]);

  const initializeColors = useCallback(
    (bloomTheme: BloomTheme) => {
      reset({
        primaryHex:
          bloomTheme?.colors?.primary?.main ?? defaultValues.primaryHex,
        secondaryHex:
          bloomTheme?.colors?.secondary?.main ?? defaultValues.secondaryHex,
        dealsHex: bloomTheme?.colors?.deals?.main ?? defaultValues.dealsHex,
        headerHex: bloomTheme?.colors?.header?.main ?? defaultValues.headerHex,
        indicaHex:
          bloomTheme?.colors?.lineage?.indica?.main ?? defaultValues.indicaHex,
        hybridHex:
          bloomTheme?.colors?.lineage?.hybrid?.main ?? defaultValues.hybridHex,
        sativaHex:
          bloomTheme?.colors?.lineage?.sativa?.main ?? defaultValues.sativaHex,
        cbdHex: bloomTheme?.colors?.lineage?.cbd?.main ?? defaultValues.cbdHex,
      });
    },
    [reset]
  );

  const onSubmit = useCallback(
    async (data: FormData) => {
      setIsiFrameLoading(true);
      if (typeof data['retailer_url'] === 'string') {
        const normalizedUrl = normalizeUrl(data['retailer_url']);
        setRetailerUrl(normalizedUrl);
        const result = await fetchIframeHeader(normalizedUrl);
        const { ok, header } = result;
        if (ok && !header) {
          setIsIframeError(false);
          return;
        }
        setIsIframeError(true);
        throw new FormValidationError('iframe_form', [
          {
            name: 'retailer_url',
            message:
              "Looks like this website couldn't load. In the meantime, you can use the eyedropper to grab colors from other browser windows and software.",
          },
        ]);
      }
    },
    [fetchIframeHeader]
  );

  useEffect(() => {
    if (bloomMenuQuery.isSuccess) {
      initializeColors(bloomMenuQuery.data?.theme?.bloom || {});
    }
  }, [bloomMenuQuery.isSuccess, initializeColors]);

  const [hexLineageErrorMessage, setHexLineageErrorMessage] = useState('');
  const [hexColorErrorMessage, setHexColorErrorMessage] = useState('');
  const [adaLineageErrorMessage, setAdaLineageErrorMessage] = useState('');

  const buildColorsError = useCallback(
    ({
      tab,
      type,
      setErrorMethod,
    }: {
      setErrorMethod: Dispatch<SetStateAction<string>>;
      tab: 'colors' | 'lineage';
      type: 'a11y' | 'hex';
    }) => {
      let colorErrors = [];
      if (tab === 'colors') {
        const primaryError =
          errors.primaryHex?.type === type && errors.primaryHex.message;
        const secondaryError =
          errors.secondaryHex?.type === type && errors.secondaryHex.message;
        const dealsError =
          errors.dealsHex?.type === type && errors.dealsHex.message;
        const headerError =
          errors.headerHex?.type === type && errors.headerHex.message;

        colorErrors = [
          primaryError,
          secondaryError,
          dealsError,
          headerError,
        ].filter(Boolean);
      } else {
        const indicaError =
          errors.indicaHex?.type === type && errors.indicaHex.message;
        const sativaError =
          errors.sativaHex?.type === type && errors.sativaHex.message;
        const hybridError =
          errors.hybridHex?.type === type && errors.hybridHex.message;
        const cbdError = errors.cbdHex?.type === type && errors.cbdHex.message;

        colorErrors = [hybridError, indicaError, sativaError, cbdError].filter(
          Boolean
        );
      }

      if (colorErrors.length === 0) {
        setErrorMethod('');
      } else if (colorErrors.length === 1) {
        setErrorMethod(
          `${colorErrors[0]} color ${
            type === 'a11y'
              ? 'does not pass accessibility standards'
              : 'is not a valid hex color'
          }.`
        );
      } else {
        const lastError = colorErrors.pop();
        setErrorMethod(
          `${colorErrors.join(', ')} and ${lastError} colors ${
            type === 'a11y'
              ? "don't pass accessibility standards"
              : 'are not valid hex colors'
          }.`
        );
      }
    },
    [errors]
  );

  useEffect(() => {
    buildColorsError({
      tab: 'lineage',
      type: 'a11y',
      setErrorMethod: setAdaLineageErrorMessage,
    });
  }, [
    errors.hybridHex,
    errors.cbdHex,
    errors.indicaHex,
    errors.sativaHex,
    buildColorsError,
  ]);

  useEffect(() => {
    buildColorsError({
      tab: 'lineage',
      type: 'hex',
      setErrorMethod: setHexLineageErrorMessage,
    });
  }, [
    errors.hybridHex,
    errors.cbdHex,
    errors.indicaHex,
    errors.sativaHex,
    buildColorsError,
  ]);

  useEffect(() => {
    buildColorsError({
      tab: 'colors',
      type: 'hex',
      setErrorMethod: setHexColorErrorMessage,
    });
  }, [
    errors.primaryHex,
    errors.secondaryHex,
    errors.dealsHex,
    errors.headerHex,
    buildColorsError,
  ]);

  return (
    <ModalPageWrapper
      hasUnsavedChanges={!isEmpty(dirtyFields)}
      preview={{
        onClick: preview,
        disable: isLoading || isEmpty(dirtyFields) || !isEmpty(errors),
      }}
      publish={{
        onClick: publish,
        disable: isLoading || isEmpty(dirtyFields) || !isEmpty(errors),
        loading: isLoading,
      }}
      title="Colors"
    >
      <Flex flexDirection="column" minHeight="100vh" background="grays-white">
        <Flex>
          <StyledTabs value={selectedTab}>
            <StyledTab
              label="Color scheme"
              selected={selectedTab === 'color-scheme'}
              value="color-scheme"
              onClick={() => setSelectedTab('color-scheme')}
              hasError={
                !!errors.dealsHex?.types?.['a11y'] || !!hexColorErrorMessage
              }
            />
            <StyledTab
              label="Lineage tags"
              selected={selectedTab === 'lineage-tags'}
              value="lineage-tags"
              onClick={() => setSelectedTab('lineage-tags')}
              hasError={!!adaLineageErrorMessage || !!hexLineageErrorMessage}
            />
          </StyledTabs>
        </Flex>

        <BorderedFlex
          flexDirection="column"
          alignItems="center"
          py={40}
          hide={selectedTab === 'lineage-tags'}
        >
          <Flex maxWidth={780} mx={16} grow={1}>
            <Controller
              name="primaryHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'Primary',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="Primary"
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  onBlur={field.onBlur}
                  value={field.value}
                  hasError={!!errors.primaryHex}
                />
              )}
            />
            <Controller
              name="secondaryHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'Secondary',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="Secondary"
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  onBlur={field.onBlur}
                  value={field.value}
                  hasError={!!errors.secondaryHex}
                />
              )}
            />
            <Controller
              name="dealsHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'Deals',
                  a11y: (value) =>
                    isColorAccessible(value, '#fff') ||
                    'Deals color does not pass accessibility standards.',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="Deals"
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  onBlur={field.onBlur}
                  value={field.value}
                  hasError={!!errors.dealsHex}
                />
              )}
            />
            <Controller
              name="headerHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'Header',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="Header (optional)"
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  onBlur={field.onBlur}
                  value={field.value}
                  hasError={!!errors.headerHex}
                />
              )}
            />
          </Flex>

          {hexColorErrorMessage && (
            <StyledBanner
              icon={<AlertIcon />}
              label={hexColorErrorMessage}
              variant="error"
            />
          )}
          {errors.dealsHex?.type === 'a11y' && (
            <StyledBanner
              icon={<AlertIcon />}
              label={errors.dealsHex.message}
              variant="error"
            />
          )}
        </BorderedFlex>

        <BorderedFlex
          flexDirection="column"
          alignItems="center"
          py={40}
          hide={selectedTab === 'color-scheme'}
        >
          <Flex maxWidth={780} mx={16} grow={1}>
            <Controller
              name="hybridHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'Hybrid',
                  a11y: (value) => isColorAccessible(value, '#fff') || 'Hybrid',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="Hybrid"
                  onBlur={field.onBlur}
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  value={field.value}
                  hasError={!!errors.hybridHex}
                />
              )}
            />
            <Controller
              name="indicaHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'Indica',
                  a11y: (value) => isColorAccessible(value, '#fff') || 'Indica',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="Indica"
                  onBlur={field.onBlur}
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  value={field.value}
                  hasError={!!errors.indicaHex}
                />
              )}
            />
            <Controller
              name="sativaHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'Sativa',
                  a11y: (value) => isColorAccessible(value, '#fff') || 'Sativa',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="Sativa"
                  onBlur={field.onBlur}
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  value={field.value}
                  hasError={!!errors.sativaHex}
                />
              )}
            />
            <Controller
              name="cbdHex"
              control={control}
              rules={{
                validate: {
                  hex: (value) => isHexColor(value) || 'CBD',
                  a11y: (value) => isColorAccessible(value, '#fff') || 'CBD',
                },
              }}
              render={({ field }) => (
                <ColorPicker
                  label="CBD"
                  onBlur={field.onBlur}
                  onChange={(hex) => {
                    field.onChange(hex);
                  }}
                  value={field.value}
                  hasError={!!errors.cbdHex}
                />
              )}
            />
          </Flex>

          {hexLineageErrorMessage && (
            <StyledBanner
              icon={<AlertIcon />}
              label={hexLineageErrorMessage}
              variant="error"
            />
          )}
          {adaLineageErrorMessage && (
            <StyledBanner
              icon={<AlertIcon />}
              label={adaLineageErrorMessage}
              variant="error"
            />
          )}
        </BorderedFlex>

        <BorderedFlex py={40} justifyContent="center">
          <Form
            maxWidth={780}
            width="100%"
            name="iframe_form"
            onSubmit={onSubmit}
          >
            <StyledTextField
              ref={urlRef}
              label="Main website source"
              name="retailer_url"
              onFocus={() => setIsIframeError(false)}
              onBlur={() => setIsIframeError(false)}
              onChange={() => setIsIframeError(false)}
              helperText={
                isIframeError
                  ? undefined
                  : 'Provide the URL of your main website to directly match the color palette'
              }
              defaultValue={retailerUrl}
              endIcon={<Typography color="grays-mid">URL</Typography>}
              error={isIframeError}
            />
          </Form>
        </BorderedFlex>

        <BorderedFlex
          alignItems="center"
          flexDirection="column"
          grow={2}
          background="grays-ultralight"
          p={64}
        >
          {!retailerUrl && (
            <Card>
              <Card.Content>
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  px={40}
                  py={40}
                >
                  <Typography branded variant="header-bold">
                    Don't know your brand colors?
                  </Typography>
                  <Typography>
                    Enter the URL for your main website above to directly
                    reference the colors.
                  </Typography>
                </Flex>
              </Card.Content>
            </Card>
          )}

          {retailerUrl && (
            <Card width="100%">
              <Card.Content>
                {isiFrameLoading && <Loading color="black" />}

                <iframe
                  onLoad={() => setIsiFrameLoading(false)}
                  src={retailerUrl}
                  title="Preview"
                  width="100%"
                  height="720px"
                  style={{ border: 0 }}
                />
              </Card.Content>
            </Card>
          )}
        </BorderedFlex>
      </Flex>
    </ModalPageWrapper>
  );
};
