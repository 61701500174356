import { useEffect } from 'react';

import { EQUIVALENCY_TYPE, PRODUCT_TYPE } from '@jane/shared/models';
import { Form, useFormContext } from '@jane/shared/reefer';

import type { CartLimitRule } from '../../mockData';

const UNITS: Record<string, { label: string; value: string }[]> = {
  [EQUIVALENCY_TYPE]: [
    { value: 'g', label: 'g' },
    { value: 'oz', label: 'oz' },
  ],
  [PRODUCT_TYPE]: [
    { value: 'mg', label: 'mg' },
    { value: 'g', label: 'g' },
    { value: 'oz', label: 'oz' },
    { value: 'ml', label: 'ml' },
    { value: 'fl_oz', label: 'fl oz' },
  ],
};

const DEFAULT_VALUES: Record<string, string> = {
  [EQUIVALENCY_TYPE]: 'g',
  [PRODUCT_TYPE]: 'mg',
};

export const UnitSelect = ({ limitUnit }: { limitUnit?: string }) => {
  const { watch, setValue } = useFormContext<CartLimitRule>();
  const ruleType = watch('rule_type');
  const options = UNITS[ruleType || PRODUCT_TYPE];
  const defaultValue = limitUnit ?? DEFAULT_VALUES[ruleType || PRODUCT_TYPE];

  useEffect(() => {
    const result =
      options.find(({ value }) => value === limitUnit) || options[0];
    setValue(`limit_unit`, result.value);
  }, [ruleType]);

  return (
    <Form.SelectField
      label="Unit"
      name="limit_unit"
      options={options}
      required
      defaultValue={defaultValue}
      width="100%"
    />
  );
};
